import React, { useRef, useState } from "react";
import { useTheme } from "styled-components";

import Tag from "@/components/buttons/Tag/Tag";
import HelperText from "@/components/form/HelperText";
import ChevronDownFilled from "@/components/icons/ChevronDownFilled/ChevronDownFilled";
import ChevronUpFilled from "@/components/icons/ChevronUpFilled/ChevronUpFilled";
import IconWrapper from "@/components/icons/IconWrapper/IconWrapper";
import { COLORS } from "@/styles/theme";
import { useOnClickOutsideEl } from "@/utils/eventHandlers";

import { DropdownWrapper } from "./Dropdown.styled";
import { DropdownItem, DropdownProps } from "./Dropdown.types";

export default function Dropdown({
  iconLeft,
  options,
  label,
  disabled,
  helperText,
  fill,
  labelClass,
  register,
  name,
  required,
  requiredSignColor,
  onChange,
  placeholder = "Kies een optie",
  placeholderClass,
  hasError = false,
  borderColor = "primary",
  multi = false,
  extraDesc = "",
  value,
  chevronColor = "white",
  enablePlaceholderStyling = false,
  defaultValue,
  ...rest
}: DropdownProps) {
  const inputRef = useRef<HTMLSelectElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { colors } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSingle, setSelectedSingle] = useState<DropdownItem | null>(
    null,
  );
  const [selectedMulti, setSelectedMulti] = useState<DropdownItem[]>([]);

  const formRegister = register
    ? register(name, {
        required: required ? "Dit veld is verplicht" : null,
      })
    : {};

  const handleInputFocus = () => {
    inputRef?.current?.focus();
  };

  const handleOptionClick = (option: DropdownItem) => {
    if (!multi) {
      handleSelect(option);
      return;
    }

    handleAddSelectMulti(option);
  };

  const handleSelect = (option: DropdownItem) => {
    setSelectedSingle(option);

    if (formRegister) {
      formRegister?.onChange?.({ target: { name, value: option.value } });
    }

    if (onChange) onChange(option.value);
    setIsOpen(() => false);
  };

  const handleAddSelectMulti = (option: DropdownItem) => {
    let selectList = [...selectedMulti];
    const found = selectList.filter((item) => item.value === option.value);

    if (found[0]) {
      selectList = selectList.filter((item) => item.value !== option.value);
    } else {
      selectList.push(option);
    }

    if (formRegister) {
      formRegister?.onChange?.({
        target: {
          name,
          value: selectList.map((item) => item.value),
        },
      });
    }

    if (onChange) onChange(option.value);
    setSelectedMulti(selectList);
  };

  const handleRemoveItem = (id: string) => {
    let selectList = [...selectedMulti].filter((item) => item.value !== id);

    setSelectedMulti(selectList);
  };

  const generateOptionActiveStyle = (option: DropdownItem) => {
    const found = selectedMulti.filter((item) => item.value === option.value);

    if (multi && found[0]) {
      return {
        backgroundColor: colors.primary.normal,
      } as React.CSSProperties;
    }

    return {};
  };

  useOnClickOutsideEl(wrapperRef, () => {
    setIsOpen(false);
  });

  return (
    <DropdownWrapper
      hasError={hasError}
      onClick={handleInputFocus}
      ref={wrapperRef}
    >
      {!!label && (
        <label
          style={{ fontFamily: "Avenir" }}
          className={`${labelClass} text-[#fff]`}
        >
          {label}{" "}
          {required && (
            <span
              style={{ color: requiredSignColor || COLORS.tertiary.normal }}
            >
              *
            </span>
          )}
          {extraDesc && <span className={"extra-desc"}>({extraDesc})</span>}
        </label>
      )}
      <div>
        <div>
          <select
            multiple={multi}
            ref={inputRef}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={() => setIsOpen(() => false)}
            {...formRegister}
            {...rest}
          >
            <option value=""></option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
          <div
            className={`selectBox ${isOpen ? "open" : ""}`}
            style={{
              borderColor: colors[borderColor].normal,
              fontFamily: "Avenir",
            }}
            onClick={() => setIsOpen((state) => !state)}
          >
            <div className="flex items-center" style={{ fontFamily: "Avenir" }}>
              {!!iconLeft && (
                <IconWrapper style={{ marginRight: 10 }}>
                  {iconLeft}
                </IconWrapper>
              )}
              {!multi ? (
                <span
                  style={{ fontFamily: "Avenir", minHeight: "1.5rem" }}
                  className={
                    enablePlaceholderStyling
                      ? `${!value ? "text-input-placeholder" : "text-input"}`
                      : placeholderClass
                  }
                >
                  {defaultValue !== undefined && defaultValue.name !== ""
                    ? defaultValue.name
                    : (value == null || value === ""
                        ? selectedSingle?.name
                        : options.find((option) => option.value === value)
                            ?.name) || placeholder}
                </span>
              ) : (
                <span
                  style={{ fontFamily: "Avenir" }}
                  className={placeholderClass}
                >
                  {placeholder}
                </span>
              )}
            </div>
            <span style={{ display: "flex", justifyContent: "end" }}>
              {!isOpen ? (
                <ChevronDownFilled color={borderColor} fill={chevronColor} />
              ) : (
                <ChevronUpFilled color={borderColor} fill={chevronColor} />
              )}
            </span>
          </div>
          {isOpen && (
            <div
              className="selectItems"
              style={{
                borderColor: colors[borderColor].normal,
                maxHeight: "400px",
                overflow: "auto",
              }}
            >
              {options.map((option) => (
                <button
                  key={option.value}
                  type="button"
                  style={generateOptionActiveStyle(option)}
                  onClick={() => handleOptionClick(option)}
                >
                  <div className="flex">
                    {option.icon && (
                      <div className="flex w-[16px] justify-center align-middle">
                        {option.icon}
                      </div>
                    )}
                    <div style={{ marginLeft: option.icon ? "8px" : "" }}>
                      {option.name}
                    </div>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>

        <HelperText text={helperText} />
      </div>

      {selectedMulti.length > 0 && (
        <div className="mt-[16px] flex flex-wrap gap-[8px]">
          {selectedMulti.map((item, index) => (
            <Tag
              key={index}
              size="m"
              onClick={() => handleRemoveItem(item.value)}
            >
              {item.name}
            </Tag>
          ))}
        </div>
      )}
    </DropdownWrapper>
  );
}
